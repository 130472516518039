import React from "react";
const BottomBubble = () => {
  return (
    <>
      <div className=" absolute">
        {" "}
        <div className="relative top-70 left-70 rounded-full h-32 w-32 bg-yellow-300  mt-4 bg-opacity-80 2xl:top-80 2xl:left-80 2xl:mt-10"></div>
      </div>
      <div className="absolute">
        {" "}
        <div className=" relative rounded-full h-24 w-24 bg-purple-600  top-80 right-200 bg-opacity-30  2xl:top-96 2xl:right-150 2xl:mt-16"></div>
      </div>
    </>
  );
};
export default BottomBubble;
