import React from 'react'
import PublicHeader from "../common/PublicHeader";
import { Layout } from 'antd';
import PrivacyPolicy from '../common/PrivacyPolicy';

function PrivacyPolicyPage(){
    return(
        <Layout className="h-screen">
        <div className="h-full w-full overflow-hidden bg-white">
            
            <PublicHeader/>
        
           
                   <div className='flex h-full items-center w-full overflow-y-scroll justify-center'>
                      <PrivacyPolicy />
                      </div>
                    </div>
                  
               
        
       
        </Layout>
    )
}
export default PrivacyPolicyPage;