import React, { ReactNode, useEffect, useState } from 'react';
import { Layout } from 'antd';
import PrivateHeader from './PrivateHeader';
import Sidenav from './SideNav';
import ConversionWizardPage from '../pages/ConversionWizardPage';
import HistoryPage from '../pages/HistoryPage';
import { useLocation} from 'react-router-dom';

const {Content } = Layout;



const contentStyle: React.CSSProperties = {
  textAlign: 'center',
//   minHeight: 120,
//   lineHeight: '120px',
minHeight:'calc(100% px)'

};

const layoutStyle: React.CSSProperties = {
  overflow: 'hidden',
  width: 'calc(100%)',
  maxWidth: 'calc(100% )',
  position:'fixed'
};

const contentWrapperStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  paddingBottom:'16px'
};


interface LoggedInLayoutProps {
  children: ReactNode;
}

const LoggedInLayout: React.FC<LoggedInLayoutProps> = ({ children }) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>('1'); // Default active tab


  const getMenuKey = (path: string) => {
    switch (path) {
      case '/conversion-wizard/inside':
        return '1';
      case '/history':
        return '2';
      default:
        return '1';
    }
  };

  useEffect(() => {
    setActiveTab(getMenuKey(location.pathname));
  }, [location.pathname]);

  const renderContent = () => {
    switch (activeTab) {
      case '1':
        return <ConversionWizardPage />;
      case '2':
        return <HistoryPage />;
      default:
        return null;
    }
  };

  return (
    <Layout style={{ height: '100vh', ...layoutStyle }}>
        <PrivateHeader />
      <Layout>
          <Sidenav setActiveTab={setActiveTab} />
        <Content style={{...contentStyle,...contentWrapperStyle}}>
          {renderContent()}
        </Content>
      </Layout>
    </Layout>
  );
};

export default LoggedInLayout;
