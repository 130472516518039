import { AxiosError } from "axios";
import { message } from "antd";
const displayedErrorKeys = new Set<string|number>();
// const messageStyle = {
//   top: "24px", 
//   right: "0px", 
//   position: "fixed",
//   zIndex: 1000, 
//   transform: 'translateY(-50%)',
//   animation: 'slide-in 0.5s forwards',
// };
// export default function throwError(error: AxiosError |any) {
//   const { response } = error;
//   if (response?.data.error) {
//     const config: number[] = [503, 502, 401, 424];
//     if (config.includes(response.status)) return;
//     // message.error(response.data.error.message);
//     const errorKey = response.status; 
//     if (!displayedErrorKeys.has(errorKey)) {
//       displayedErrorKeys.add(errorKey);
//       message.error({content:response.data.error.message,});
//       setTimeout(() => {
//         displayedErrorKeys.delete(errorKey);

//       }, 3000); 
//     }
//   }
// }



export function throwSuccess(msg: string) {
  message.success({content:msg,});
}
export function throwError(msg: string) {
    message.error({content:msg,});
  }

// const messageStyle = {
//   right: "0px",
//   position: "fixed",
//   zIndex: 1000,
//   transform: 'translateY(-50%)',
//   animation: 'slide-in 0.5s forwards',
// };

let messageTop = 24; // Initial top position
const messageSpacing = 40; // Vertical spacing between messages
export default function throwApiError(error: AxiosError |any) {
  const { response } = error;
  if (response?.data.error) {
    const config: number[] = [503, 502, 401, 424];
    if (config.includes(response.status)) return;
    const errorKey = response.status;
    if (!displayedErrorKeys.has(errorKey)) {
      displayedErrorKeys.add(errorKey);
      message.error({
        content: response.data.error.message,
        // style: { ...messageStyle, top: `${messageTop}px` }, // Set top position dynamically
      });
      setTimeout(() => {
        displayedErrorKeys.delete(errorKey);
      }, 5000);
      messageTop += messageSpacing; // Increment top position for next message
    }
  }
}
