import { Button} from "antd";
import ContentHeader from "../common/ContentHeader";
import React, { useEffect, useState } from "react";
import BillingPlanModal from "./BillingPlanModal";
import { GetUserCredits } from "../api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { updateCredits } from "../store/userSlice";

function ConversionWizardHeader() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state.user.user);
  const [userCredits, setUserCredits] = useState(0);
  const dispatch = useDispatch();


  const handleCancelilling = () => {
    setIsModalOpen(false);
  };
  const handleBillingOpen = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const fetchUserCredits = async () => {
      try {
        // Call the GetUserCredits API
        const response = await GetUserCredits(user?.user_id);
        // Update the user credits state with the response
        setUserCredits(response.credits);
        dispatch(updateCredits(response?.credits));

      } catch (error) {
        // Handle errors if any
        console.error('Error fetching user credits:', error);
      }
    };

    // Call the function to fetch user credits when component mounts
    fetchUserCredits();
  }, []); //

  // const customFormat = (percent: any) => `${percent}%`;

  return (
    // <div>
    <ContentHeader>
      <div
        className="py-3 flex flex-row border-b-1 w-full justify-between"
        style={{ borderColor: "#EBEBEB" }}
      >
        <div className="flex text-base font-semibold items-center h-full">
          {" "}
          Conversion Wizard
        </div>
        <div className="flex flex-row space-x-4 items-center">
          <div className="text-3xl font-bold">{userCredits}</div>
          {/* <Progress percent={70} type="circle" size={60}       format={userCredits} 
 /> */}
          <div className="flex flex-col space-y-1 text-sm">
            <div className="font-semibold">Remaining credits </div> 1 credit
            will be used <br /> to parse 1 resume.
          </div>
          <div className="items-center pl-4">
            <Button
              onClick={handleBillingOpen}
              type="primary"
              style={{ height: "32px" }}
            >
              Buy credits
            </Button>
          </div>
        </div>
        <BillingPlanModal
          isModalOpen={isModalOpen}
          handleCancel={handleCancelilling}
          // handlebillingConfirm={handlebillingConfirm}
        />
      </div>
    </ContentHeader>
    // </div>
  );
}
export default ConversionWizardHeader;
