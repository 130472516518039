import React, { useEffect } from "react";
import useDrivePicker from "react-google-drive-picker";
import DriveIcon from "../Icon/DriveComponent";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";



declare global {
  interface Window {
    gapi: any;
    google: any;
  }
}
interface GooglePickerProps {
  handleSelectFiles: (value: any) => void;
}
function GooglePicker(props: GooglePickerProps) {
  const [openPicker, authResponse] = useDrivePicker();
  const user = useSelector((state: RootState) => state.user.user);
  const CLIENT_ID =process.env.REACT_APP_CLIENT_ID;
  // const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    function loadGapiAndPicker() {
      const script = document.createElement("script");
      script.src = "https://apis.google.com/js/api.js";
      script.onload = () => {
        window.gapi.load("client:picker", initializeGapiClient);
      };
      document.body.appendChild(script);
    }

    function initializeGapiClient() {
      window.gapi.client
        .init({
          apiKey: '',
          clientId: CLIENT_ID,
          token: user?.access_token,
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
          ],
          scope: "https://www.googleapis.com/auth/drive.file",
        })
        // .then(() => {
        //   console.log("GAPI client loaded for API");
        // })
        .catch((error: any) => {
          console.error("Error loading GAPI client for API", error);
        });
    }

    loadGapiAndPicker();
  }, []);

  const handleOpenPicker = () => {
    
    CLIENT_ID&&
    openPicker({
      clientId: CLIENT_ID,
      developerKey: '',
      token: user?.access_token,
      viewId: "DOCS",disableDefaultView:false,
      customViews: [
        'DOCS',
        "DOCUMENTS",
        "PDFS",
        "FOLDERS",
      ],
      // viewMimeTypes:
      //   "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain",
      showUploadView: true,
      showUploadFolders: true,
      setIncludeFolders: true,appId:process.env.REACT_APP_PROJECT_NUMBER,
      setSelectFolderEnabled: false,
      supportDrives: true,
      multiselect: true,
      callbackFunction: async (data) => {
        if (
          data.docs &&
          data.docs[0].mimeType === "application/vnd.google-apps.folder"
        ) {
          const folderId = data.docs[0].id;
          try {
            const response = await window.gapi.client.drive.files.list({
              q: `'${folderId}' in parents`,
              fields: "files(id, name)",
              pageSize:200,
            });
            const files = response.result.files;
            const fileCount = files.length;
            props.handleSelectFiles({ ...data, fileCount });
          } catch (error) {
            console.error("Error listing files in folder", error);
          }
        } else {
          props.handleSelectFiles(data);
        }
      },
    });
  };

  return (
    <div>
      <Button
        icon={<DriveIcon />}
        className="items-center flex w-full justify-center h-10"
        onClick={() => handleOpenPicker()}
        style={{ width: "315px" }}
      >
        Upload From Google drive
      </Button>
    </div>
  );
}
export default GooglePicker;
