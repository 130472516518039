import React from "react";
import { Layout, Avatar, Button, Popover, Divider, Space, Tooltip } from "antd";
import { QuestionCircleOutlined, LogoutOutlined } from "@ant-design/icons";
import ResumeParserLogo from "../images/RP_180X180.png";
import { clearUser } from "../store/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";

const { Header } = Layout;

const headerStyle: React.CSSProperties = {
  textAlign: "center",
  color: "#fff",
  height: 64,
  paddingInline: 16,
  lineHeight: "64px",
  width: "100vw",
};

const PrivateHeader = () => {
  const user = useSelector((state: RootState) => state.user.user);

  const dispatch = useDispatch();

  const handleLogout = () => {
    // Dispatch the clearUser action
    dispatch(clearUser());
    // Additional logout logic if needed, e.g., clearing tokens, redirecting
    localStorage.removeItem("authToken"); // or your token name
    // window.location.href = `${process.env.REACT_APP_BASE_URL}/conversion-wizard`; // redirect to login page
  };
  const popoverContent = (
    <>
      <div
        className="flex items-start justify-start"
        style={{ minWidth: 250, minHeight: "60px" }}
      >
        <Space style={{ padding: "4px" }}>
          {user.profile ? (
            <div className="flex items-center justify-center">
      <img src={user.profile} alt="Avatar" className="h-10 w-10 rounded-full object-cover" />
    </div>          ) : (
            <Avatar
              shape="circle"
              size="large"
              className="uppercase text-xl font-semibold"
              style={{ backgroundColor: "#f56a00" }}
            >
              {user?.name[0].toUpperCase()}
            </Avatar>
          )}
          <div className="flex flex-col">
            <strong>
              <div className="w-44 truncate">{user.name}</div>
            </strong>
            <div className=" w-44 truncate">{user.email}</div>
          </div>
        </Space>
      </div>
      <Divider style={{ margin: "10px" }} />
      <div className="flex justify-end">
        <Button
          type="primary"
          // size="default"
          icon={<LogoutOutlined />}
          onClick={handleLogout}
        >
          Log Out
        </Button>
      </div>
    </>
  );

  return (
    <Header className=" justify-between flex flex-row " style={headerStyle}>
      <div
        className="flex flex-row items-center sticky text-white font-semibold text-2xl"
        style={{ marginRight: "auto" }}
      >
        <img src={ResumeParserLogo} alt="avatar" style={{ height: "50px" }} />{" "}
        Resume Parser
      </div>
      <div
        className="flex flex-row items-center sticky"
        style={{ marginLeft: "auto" }}
      >
        <div className="pr-4">
          <Tooltip title="Need help? Talk to support">
          <a href="mailto:support@resumeparser.in?subject=I Need Help with Resume Parsing">
            <QuestionCircleOutlined style={{ color: "white" }} />
          </a>
          </Tooltip>
        </div>
        <Popover content={popoverContent} trigger="hover">
          {user.profile  ? (
            // <Avatar
            //   size="large"
            //   src={user.profile}
            //   shape="circle"
            //   alt={user?.name[0]}
            // />
            <div className="flex items-center justify-center">
      <img src={user.profile} alt="Avatar" className="h-10 w-10 rounded-full object-cover" />
    </div>
          ) : (
            <Avatar
              shape="circle"
              size="large"
              className="uppercase text-xl font-semibold object-cover"
              style={{ backgroundColor: "#f56a00" }}
            >
              {user?.name[0].toUpperCase()}
            </Avatar>
          )}
        </Popover>
      </div>
    </Header>
  );
};

export default PrivateHeader;
