import { Button } from "antd";
import React, { useEffect, useState } from "react";
import GoogleIcon from "../Icon/GoogleIcon";
import { GetGoogleAccessLink } from "../api";


const SignInWithGoogleButton = () => {
const [authUrl, setAuthUrl] = useState("");

useEffect(() => {
  const getAuthUrl = async () => {
      try {
          const response = await GetGoogleAccessLink();
          setAuthUrl(response.url);
      } catch (error) {
          console.error("Error fetching Google auth URL", error);
      }
  };

  getAuthUrl();
}, []);

const handleLogin = () => {
  window.location.href = authUrl;  
};

  return (
      <Button
      icon={<GoogleIcon/>}
      className="items-center flex w-full justify-center h-10"
      onClick={handleLogin}
      // onFailure={onFailure}
      >
        Connect with Google
      </Button>
  );
};

export default SignInWithGoogleButton;


