import React from "react";
function TermsOfUse() {
  return (
    <div className="mx-36 py-12 flex flex-col h-full items-center ">
      <div className="max-w-4xl">
        <div className="items-center text-4xl font-bold mb-2 ">
          Online Terms of Service{" "}
        </div>
        <div className="flex text-left mt-8 mb-4">Last Updated: May 22, 2024</div>
        <div className="flex text-left">
          These Terms of Use ("Terms" or "Agreement") are a legally binding
          agreement between you ("User" or "you") and Workship, Inc.
          ("Workship," "we," "our," or "us") governing your use of the Resume
          Parser services ("Services") provided by Workship. By accessing or
          using the Services, you agree to these Terms.
        </div>

        <div className="items-center text-2xl font-bold mb-2 text-left my-6">
          1. Acceptance of Terms{" "}
        </div>
        <div className="flex text-left">
          By signing up for and/or using the Services, you agree to comply with
          and be bound by these Terms. If you do not agree to these Terms, do
          not use the Services. If you are using the Services on behalf of an
          organization, you represent that you have the authority to bind that
          organization to these Terms, and you agree to these Terms on behalf of
          that organization.
        </div>

        <div className="items-center text-2xl font-bold mb-2 text-left my-6">
          2. Services{" "}
        </div>
        <div className="flex text-left">
          The Services allow you to parse documents from various formats,
          including but not limited to PDF, DOC, HTML, and TXT, using AI models
          and algorithms.
        </div>
        <div className="flex text-left my-4 text-base font-bold">
          2.1. Paid and Free Users
        </div>
        <div className="text-left pl-8">
        <ul className="list-disc">
            <li>
              Paid Users: Users who sign up using their Google account and
              purchase credits can access the full range of Services. One credit
              is used per documents parsed.
            </li>
            <li>
              Free Users: Users who do not sign up can use the Services on a
              limited scale.
            </li>
          </ul>
        </div>

        <div className="flex text-left my-4 text-base font-bold">
          2.2. Credits
        </div>
        <div className="flex text-left">
          Paid users receive credits upon payment. Each credit allows for the
          parsing of one resume. Credits are subject to the pricing and terms
          outlined at the time of purchase.
        </div>

        <div className="items-center text-2xl font-bold mb-2 text-left my-6">
          3. User Data and Privacy{" "}
        </div>
        <div className="flex text-left my-4 text-base font-bold">
          3.1. User Data
        </div>

        <div className="flex text-left">
          You retain ownership of the data and content you provide to the
          Services ("User Data"). You are responsible for the accuracy, quality,
          and legality of the User Data and the means by which you acquired it.
        </div>

        <div className="flex text-left my-4 text-base font-bold">
          3.2. Data Usage
        </div>

        <div className="flex text-left">
          By using the Services, you grant Resume Parser a non-exclusive,
          worldwide, royalty-free license to use, collect, transfer, and process
          User Data to provide and improve the Services, including improving AI
          models and algorithms.
        </div>

        <div className="flex text-left my-4 text-base font-bold">
          3.3. Fair Use Policy
        </div>

        <div className="flex text-left">
          To ensure the optimal performance and reliability of our Resume
          Parser, we have implemented a fair use policy to prevent potential
          abuse.
        </div>
        <div className="flex text-left ml-8">
        <ul className="list-disc">
            <li>
              Paid Users: Users with a paid subscription plan are limited to
              processing a maximum of 500 resumes per hour.
            </li>
            <li>
              Free Users: Users without a subscription can process up to 50
              resumes per hour.
            </li>
          </ul>
        </div>

        <div className="flex text-left">
          These limits are in place to maintain the quality and availability of
          our services for all users. If you require higher limits, please
          contact our support team to discuss a customized plan that fits your
          needs.
        </div>

        <div className="flex text-left my-4 text-base font-bold">
          3.4. Data Security
        </div>

        <div className="flex text-left">
          We will implement reasonable measures to protect your User Data.
          However, you are responsible for maintaining appropriate security and
          backup copies of your User Data.
        </div>

        <div className="items-center text-2xl font-bold mb-2 text-left my-6">
          4. Account Registration{" "}
        </div>

        <div className="flex text-left my-4 text-base font-bold">
          4.1. Account Information
        </div>

        <div className="flex text-left">
          To access and use the Services, including receiving Output Data, you
          must create an account. You agree to provide accurate and up-to-date
          information during the registration process and to update such
          information as necessary to keep it accurate and current. This ensures
          that we can contact you if needed, such as to deliver important
          notices.
        </div>

        <div className="flex text-left my-4 text-base font-bold">
          4.2. Eligibility Restrictions
        </div>

        <div className="flex text-left">
          The Services are intended for use by individuals who are at least 18
          years old or the age of majority in their jurisdiction. You must
          ensure that only your employees or service providers, or the employees
          or service providers of your wholly or majority-owned subsidiaries,
          who have been expressly authorized by you ("Authorized Users"), use or
          access the Services. Authorized Users must comply with these Terms.
          You may not use the Services if you or any of your Authorized Users
          are competitors of Resume Parser, as determined by us in our sole
          discretion.
        </div>

        <div className="flex text-left my-4 text-base font-bold">
          4.3. Account Security
        </div>

        <div className="flex text-left">
          While we implement security measures to protect your information, we
          cannot guarantee absolute security. You are responsible for any
          activity that occurs through your account and must ensure the
          confidentiality and security of your account credentials. You agree
          to:
        </div>

        <div className="flex text-left pl-8">
        <ul className="list-disc">
            <li>
              Maintain the confidentiality and security of your account
              information and credentials, including your username and password.
            </li>
            <li>
              Not share your account credentials and restrict access to your
              devices.
            </li>
            <li>
              Access the Services and our network, systems, or applications only
              through encrypted connections.
            </li>
            <li>
              Keep your operating system up-to-date with patches and have active
              anti-malware software on devices used to connect to the Services.
            </li>
            <li>
              Revoke access to the Services for terminated employees or other
              users within 24 hours of their termination.
            </li>
            <li>
              Promptly notify us (within 48 hours) of any security incidents
              that could affect us, such as compromised credentials or
              lost/stolen devices.
            </li>
            <li>
              Contact our support team at support@resumeparser.in if you suspect
              any vulnerabilities in our Services.
            </li>
          </ul>
        </div>
        <div className="items-center text-2xl font-bold mb-2 text-left my-6">
          5. Use of Services{" "}
        </div>

        <div className="flex text-left my-4 text-base font-bold">
          5.1. Permitted Use
        </div>
        <div className="flex text-left">
          You may use the Services for your internal business purposes in
          accordance with these Terms. You agree not to:
        </div>
        <div className="flex text-left">
          While we implement security measures to protect your information, we
          cannot guarantee absolute security. You are responsible for any
          activity that occurs through your account and must ensure the
          confidentiality and security of your account credentials. You agree
          to:
        </div>

        <div className="flex text-left my-4 text-base font-bold">
          5.2. Compliance with Laws
        </div>
        <div className="flex text-left">
          You agree to use the Services in compliance with all applicable laws
          and regulations, including data protection and privacy laws.
        </div>

        <div className="flex text-left my-4 text-base font-bold">
          5.3 Accuracy of Data Generated
        </div>
        <div className="flex text-left">
          5.3 Accuracy of Data Generated Artificial intelligence and machine
          learning are continually evolving fields. We are dedicated to
          improving our Resume Parser services to enhance their accuracy,
          reliability, and overall quality. Despite these efforts, the
          probabilistic nature of machine learning means that, at times, the
          Output files generated as Output may not perfectly reflect real
          people, places, or facts. When using our Resume Parser, you
          acknowledge and agree that:
        </div>

        <div className="flex text-left pl-8">
        <ul className="list-disc">
            <li>
              The Output may not always be accurate. You should not rely solely
              on the Output files generated by our Resume Parser as your only
              source of truth or factual information, nor as a substitute for
              professional judgment.
            </li>
            <li>
              You are responsible for evaluating the accuracy and
              appropriateness of the Output for your specific use case, which
              includes conducting a human review where necessary, before using
              or sharing the CSV files generated by the service.
            </li>
            <li>
              You must not use any Output related to an individual for decisions
              that could have significant legal or material consequences, such
              as decisions related to credit, education, employment, housing,
              insurance, legal matters, or medical considerations.
            </li>
            <li>
              Our Resume Parser may sometimes produce incomplete, incorrect, or
              offensive Output that does not represent the views of Resume
              Parser. Any references to third-party products or services within
              the Output do not imply endorsement or affiliation by those third
              parties with Resume Parser.
            </li>
          </ul>
        </div>

        <div className="items-center text-2xl font-bold mb-2 text-left my-6">
          6. Fees and Payment{" "}
        </div>

        <div className="flex text-left my-4 text-base font-bold">6.1. Fees</div>
        <div className="flex text-left">
          The fees for the Services are as set forth on our website or otherwise
          communicated to you. All fees are non-refundable unless otherwise
          specified.
        </div>

        <div className="flex text-left my-4 text-base font-bold">
          6.2. Payment
        </div>
        <div className="flex text-left">
          You must provide a valid payment method to pay for the Services. By
          providing payment information, you authorize Resume Parser to charge
          the applicable fees to your payment method. You are responsible for
          all applicable taxes.
        </div>

        <div className="flex text-left my-4 text-base font-bold">
          6.3. Late Payments
        </div>
        <div className="flex text-left">
          If any fee is not paid in a timely manner, we reserve the right to
          suspend or terminate your access to the Services.
        </div>

        <div className="items-center text-2xl font-bold mb-2 text-left my-6">
          7. Intellectual Property{" "}
        </div>

        <div className="flex text-left">
          Resume Parser owns all rights, title, and interest in and to the
          Services, including all related intellectual property rights. This
          Agreement does not convey any rights of ownership to you. You are
          granted a limited, non-exclusive, non-transferable, revocable license
          to use the Services in accordance with these Terms.
        </div>

        <div className="items-center text-2xl font-bold mb-2 text-left my-6">
          8. Confidentiality{" "}
        </div>

        <div className="flex text-left">
          Each party agrees to protect the other party's confidential
          information with the same degree of care that it uses to protect its
          own confidential information, but in no event with less than a
          reasonable degree of care. Confidential information shall not include
          information that is publicly known, already known to the recipient,
          independently developed, or obtained from a third party without breach
          of any obligation of confidentiality.
        </div>

        <div className="items-center text-2xl font-bold mb-2 text-left my-6">
          9. Warranties and Disclaimers{" "}
        </div>

        <div className="flex text-left my-4 text-base font-bold">
        9.1. Warranties
        </div>
        <div className="flex text-left">
        We warrant that we will provide the Services with reasonable care and skill. However, we do not warrant that the Services will be uninterrupted, error-free, or completely secure.
        </div>
        <div className="flex text-left my-4 text-base font-bold">
        9.2. Disclaimers
        </div>
        <div className="flex text-left">
        Except as expressly provided herein, the Services are provided "as is" and "as available," without warranties of any kind, whether express, implied, statutory, or otherwise.

        </div>

        <div className="items-center text-2xl font-bold mb-2 text-left my-6">
          10. Limitation of Liability{" "}
        </div>

        <div className="flex text-left">
        To the maximum extent permitted by law, Resume Parser shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Services; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Services.

        </div>

        <div className="flex text-left">
        Our aggregate liability arising out of or relating to these terms or the Services will not exceed the greater of the amount you have paid us in the past twelve months or fifty dollars ($50).

        </div>

        <div className="items-center text-2xl font-bold mb-2 text-left my-6">
          11. Indemnification{" "}
        </div>

        <div className="flex text-left">
        You agree to indemnify, defend, and hold harmless Resume Parser, its affiliates, officers, directors, employees, agents, and licensors from and against any claims, actions, suits, or proceedings, as well as any related liabilities, damages, settlements, penalties, fines, costs, or expenses (including reasonable attorneys' fees and other litigation expenses) arising out of or relating to your use of the Services or your violation of these Terms.

        </div>

        <div className="items-center text-2xl font-bold mb-2 text-left my-6">
          12. Term and Termination{" "}
        </div>

        <div className="flex text-left">
        This Agreement will remain in effect until terminated by either party.

        </div>


        <div className="flex text-left my-4 text-base font-bold">
        12.1. Term
        </div>
        <div className="flex text-left">
        This Agreement will remain in effect until terminated by either party.

        </div>
        <div className="flex text-left my-4 text-base font-bold">
        12.2. Termination
        </div>
        <div className="flex text-left">
        We may terminate or suspend your access to the Services at any time, with or without cause, with or without notice. You may terminate your use of the Services at any time by ceasing to use the Services.

        </div>
        <div className="flex text-left my-4 text-base font-bold">
        12.3. Effects of Termination
        </div>
        <div className="flex text-left">
        Upon termination of your access to the Services, your right to use the Services will immediately cease. All provisions of this Agreement that by their nature should survive termination, including ownership provisions, warranty disclaimers, indemnity, and limitations of liability.

        </div>




        <div className="items-center text-2xl font-bold mb-2 text-left my-6">
          13. Dispute Resolution{" "}
        </div>

        <div className="flex text-left my-4 text-base font-bold">
        13.1. Governing Law
        </div>
        <div className="flex text-left">
        This Agreement and any dispute arising out of or in connection with it shall be governed by and construed in accordance with the laws of the State of Delaware, without regard to its conflict of law principles.

        </div>

        <div className="flex text-left my-4 text-base font-bold">
        13.2. Arbitration
        </div>
        <div className="flex text-left">
        Any disputes arising out of or relating to this Agreement shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association. The arbitration shall be conducted in Delaware, and the award shall be final and binding on the parties.

        </div>

        <div className="flex text-left my-4 text-base font-bold">
        13.3. Class Action Waiver
        </div>
        <div className="flex text-left">
        You agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated, or representative action.

        </div>

        <div className="items-center text-2xl font-bold mb-2 text-left my-6">
          14. Miscellaneous{" "}
        </div>

        <div className="flex text-left my-4 text-base font-bold">
        14.1. Changes to Terms
        </div>
        <div className="flex text-left">
        We may modify these Terms at any time. If we make material changes to these Terms, we will notify you by email or by posting a notice on our website prior to the effective date of the changes. Your continued use of the Services after the effective date constitutes your acceptance of the modified Terms.

        </div>

        <div className="flex text-left my-4 text-base font-bold">
        14.2. Entire Agreement
        </div>
        <div className="flex text-left">
        These Terms constitute the entire agreement between you and Resume Parser regarding the use of the Services and supersede all prior agreements and understandings, whether written or oral, relating to their subject matter.

        </div>


        <div className="flex text-left my-4 text-base font-bold">
        14.3. Severability
        </div>
        <div className="flex text-left">
        If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that these Terms will otherwise remain in full force and effect and enforceable.

        </div>

        <div className="flex text-left my-4 text-base font-bold">
        14.4. Waiver
        </div>
        <div className="flex text-left">
        The failure of either party to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision.

        </div>

        <div className="flex text-left my-4 text-base font-bold">
        14.5. Assignment
        </div>
        <div className="flex text-left">
        You may not assign these Terms or any of your rights or obligations under these Terms without our prior written consent. We may assign these Terms at any time without notice or consent.

        </div>

        <div className="flex text-left my-4 text-base font-bold">
        14.6. Notices
        </div>
        <div className="flex text-left">
        All notices to Resume Parser shall be in writing and sent to support@resumeparser.in. Notices to you may be sent to the email address provided by you during account registration.

        </div>

        <div className="items-center  text-left my-6 ">
        Thank you for choosing Resume Parser Resume Parser Services! If you have any questions or concerns about these Terms, please contact us at &nbsp;<a href="mailto:support@resumeparser.in?subject=I Need Help with Resume Parsing"style={{ color: "#009D79" }}
>support@resumeparser.in</a>. 

</div>
<div className="mb-36">© 2024 by Resume Parser.</div>



      </div>
    </div>


  );
}
export default TermsOfUse;
