// store/userSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  user: JSON.parse(localStorage.getItem('user') || 'null'),
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{ id:String,client_id:String,client_secret:String,access_token:String,refresh_token:String,expires_at:String,user_id:String, email: string ,name: string;created_at:string,updated_at:string,credits:number}>) => {
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload));
    },
    updateCredits: (state, action: PayloadAction<number>) => {
      if (state.user) {
        state.user.credits = action.payload;
        localStorage.setItem('user', JSON.stringify(state.user));
      }
    },
    clearUser: (state) => {
      state.user = null;
      localStorage.removeItem('user');
    },
  },
});

export const { setUser, updateCredits, clearUser } = userSlice.actions;
export default userSlice.reducer;
