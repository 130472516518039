function PrivacyPolicy(){
    return(
<div className="mx-36 py-12 flex flex-col h-full items-center ">
      <div className="max-w-4xl">
      <div className=" text-4xl font-bold mb-2 items-center">
      Privacy Policy{" "}
        </div>
        <div className="items-center   mb-2 text-left my-6">
    Last Updated: May 22, 2024
</div>
<div className="flex text-left mb-6">
    Resume Parser ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share information when you use our Resume Parser service ("Service").
</div>

<div className="items-center text-2xl font-bold mb-2 text-left my-6">
    1. Information We Collect
</div>
<div className="flex text-left">
    <b>Account Information:</b> When you sign up for our Service using your Google account, we collect your email address, name, and any other information you choose to provide.
</div>
<div className="flex text-left">
    <b>Resume Data:</b> To parse resumes, we collect and process the content of resumes you upload in various formats, including but not limited to PDF, DOC, HTML, and TXT.
</div>
<div className="flex text-left">
    <b>Usage Information:</b> We collect information about how you use our Service, such as the number of documents processed, the time and date of your activities, and any errors encountered.
</div>
<div className="flex text-left">
    <b>Technical Information:</b> We collect information about the devices you use to access our Service, including IP addresses, browser types, and operating systems.
</div>

<div className="items-center text-2xl font-bold mb-2 text-left my-6">
    2. How We Use Information
</div>
<div className="flex text-left">
    We use the information we collect to:
</div>
<div className="flex text-left ml-4">
    <ul className="list-disc">
        <li>Provide, operate, and maintain our Service.</li>
        <li>Improve, personalize, and expand our Service.</li>
        <li>Analyze usage patterns to enhance user experience.</li>
        <li>Communicate with you, including responding to your inquiries and providing updates about the Service.</li>
        <li>Ensure compliance with our Terms of Service and prevent misuse.</li>
    </ul>
</div>

<div className="items-center text-xl font-bold mb-2 text-left my-6">
Limited Use Policy
</div>
<div className="flex text-left">
        <p>
            Resume Parser's use and transfer to any other app of information received from Google APIs will adhere to &nbsp;
            <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" style={{color:"#009D79"}}>
                Google API Services User Data Policy
            </a>, including the Limited Use requirements.
        </p>
    </div>

<div className="items-center text-2xl font-bold mb-2 text-left my-6">
    3. How We Share Information
</div>
<div className="flex text-left">
    We do not share your personal information with third parties, except in the following circumstances:
</div>
<div className="flex text-left ml-4">
    <ul className="list-disc">
        <li><b>Service Providers:</b> We may share information with third-party service providers who perform services on our behalf, such as hosting, data analysis, and customer support.</li>
        <li><b>Legal Requirements:</b> We may disclose information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</li>
        <li><b>Business Transfers:</b> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring entity.</li>
    </ul>
</div>

<div className="items-center text-2xl font-bold mb-2 text-left my-6">
    4. Data Retention
</div>
<div className="flex text-left">
    We retain your information for as long as necessary to provide our Service and fulfill the purposes outlined in this Privacy Policy. We may also retain and use your information to comply with legal obligations, resolve disputes, and enforce our agreements.
</div>

<div className="items-center text-2xl font-bold mb-2 text-left my-6">
    5. Security
</div>
<div className="flex text-left">
    We implement reasonable security measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no security system is impenetrable, and we cannot guarantee the absolute security of your information.
</div>

<div className="items-center text-2xl font-bold mb-2 text-left my-6">
    6. Your Rights
</div>
<div className="flex text-left">
    Depending on your location, you may have the following rights regarding your personal information:
</div>
<div className="flex text-left ml-4">
    <ul className="list-disc">
        <li><b>Access:</b> You can request access to the personal information we hold about you.</li>
        <li><b>Correction:</b> You can request correction of any inaccurate or incomplete personal information.</li>
        <li><b>Deletion:</b> You can request deletion of your personal information, subject to certain legal exceptions.</li>
        <li><b>Restriction:</b> You can request that we restrict the processing of your personal information.</li>
        <li><b>Objection:</b> You can object to the processing of your personal information.</li>
        <li><b>Data Portability:</b> You can request a copy of your personal information in a structured, machine-readable format.</li>
    </ul>
</div>
<div className="flex text-left">
    To exercise any of these rights, please contact us at <a href="mailto:support@resumeparser.in">support@resumeparser.in</a>.
</div>

<div className="items-center text-2xl font-bold mb-2 text-left my-6">
    7. Children's Privacy
</div>
<div className="flex text-left">
    Our Service is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have collected personal information from a child under 18, we will take steps to delete such information.
</div>

<div className="items-center text-2xl font-bold mb-2 text-left my-6">
    8. Changes to This Privacy Policy
</div>
<div className="flex text-left">
    We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website and updating the "Last Updated" date at the top. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
</div>

<div className="items-center text-2xl font-bold mb-2 text-left my-6">
    9. Contact Us
</div>
<div className="flex text-left">
    If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at &nbsp;<a href="mailto:support@resumeparser.in?subject=I Need Help with Resume Parsing"style={{ color: "#009D79" }}
>support@resumeparser.in</a>. 
</div>

<div className="flex text-left mt-6">
    By using our Service, you agree to the collection and use of information in accordance with this Privacy Policy.
</div>

<div className=" mt-6 mb-36">© 2024 by Resume Parser.</div>




        </div>    
</div>
    )
}
export default PrivacyPolicy;