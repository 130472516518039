import React, { ReactNode } from "react";
interface ContentHeaderProps {
  children: ReactNode;
}

const ContentHeader: React.FC<ContentHeaderProps> = ({ children }) => {
  return <div className="bg-white px-4 overflow-hidden " style={{height:"112px",borderBottom:'1px solid #EBEBEB'}}>{children}</div>;
};

export default ContentHeader;
