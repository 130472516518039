import React, { useEffect, useState } from "react";
import { Button, Empty, Modal, Spin, Table, Tooltip } from "antd";
import { GetDownloadsLink, GetUploadDiectory } from "../api";
import { LoadingOutlined ,RedoOutlined} from "@ant-design/icons";
import { throwError } from "../common/throwmessage";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import PublicConversionTable from "./PublicConversionTable";
import { useNavigate } from "react-router-dom";
import { ColumnType } from "antd/es/table";


interface DataItem {
  serialNo:number,
  key: string;
  transactionId: string;
  dateTime: string;
  fileCount: number;
  completed: number;
  discarded: number;
  running: number;
  resultLink: string | JSX.Element;
}

const HistoryMiddleContent = () => {
  const [data, setData] = useState<DataItem[]>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    style: { position: "sticky" },
  });
  const [loading, setLoading] = useState(false);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [error, setError] = useState(false);
  const [tableHeight, setTableHeight] = useState(0); // Default height
  // const[tableData,setTableData]=useState([])

  const user = useSelector((state: RootState) => state.user.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<any>(null);
  const [modalTableHeader, setModalTableHeader] = useState<any>([]);
  const [downloadFileUrl, setDownloadFileUrl] = useState<any>("");
  const navigate = useNavigate();


  const refreshPage = () => {
    navigate(0);
}

  const showModal = async (content: any) => {
    setIsModalOpen(true);
    try {
      setTableDataLoading(true);
      const response = await GetDownloadsLink(content.id, user?.user_id); // Call the API to download the file
      setDownloadFileUrl(response?.url);
      // const TablecontentArray =[response.metadata[0],response.metadata[1],response.metadata[2],response.metadata[3],response.metadata[4]]
      // console.log(TablecontentArray,"table content array")
      let firstFiveObjects = response?.metadata?.slice(0, 5);
      let ColumnHeader=response?.headers
      setModalTableHeader(ColumnHeader)

      setModalContent(firstFiveObjects);
      setTableDataLoading(false);
    } catch (error) {
      throwError("unable to get Download url");
    }
  };

  const handleOk = () => {
    window.location.href = downloadFileUrl;
    setIsModalOpen(false);
    setModalContent(null);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const fetchData = async (page: number, pageSize: number) => {
    setLoading(true);
    setError(false);
    try {
      const response = await GetUploadDiectory(page, pageSize, user?.user_id);
      const uploads = response?.uploads;
      // const isDisabled = (uploads.items.completed < 5 || item.fileCount <= 5) && item.running !== 0;
  
      setLoading(false);
      setData(
        uploads?.items?.map((item: any, index: number) => {
          const date = new Date(item?.created_at);
          const formattedDate = date.toLocaleDateString("en-GB", {
            day: "numeric",
            month: "long",
            year: "numeric",
          });
          const formattedTime = date.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
          const isDisabled =  item.running !== 0;
  
          return {
            key: item?.id,
            serialNo: index + 1 + (page - 1) * pageSize, // Calculate the serial number based on the page and pageSize
            transactionId: item?.id,
            dateTime: `${formattedDate}, ${formattedTime}`,
            fileCount: item?.total_files,
            completed: item?.completed,
            discarded: item?.discarded,
            running: item?.running,
            resultLink: (
              <div>
                <Button
                  type="text"
                  onClick={() => {
                    handleDownloadClick(item?.id);
                  }}
                  style={{
                    color: isDisabled? "gray" : "blue",
                  }}
                  loading={buttonLoading}
                  disabled={isDisabled}

                >
                  Download
                </Button>
                {item.running > 0 && (
                  <>
                    <span>
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: 16,
                              color: "green",
                              fontWeight: "600",
                            }}
                            spin
                          />
                        }
                        style={{ marginLeft: "4px", marginRight: "4px" }}
                      />
                    </span>
                    <span>
                      <Tooltip title="Get updated resume statuses.">
                      <Button type="text" onClick={refreshPage} icon={<RedoOutlined style={{ color: 'green' }} />} />
                      </Tooltip>
                    </span>
                  </>
                )}
              </div>
            ),
            resultPreview: (
              <Button
                type="text"
                onClick={() => showModal(item)}
                style={{
                  color: isDisabled? "gray" : "blue",
                }}       
                         disabled={isDisabled}

              >
                Preview
              </Button>
            ),
          };
        })
      );
  
      setPagination({
        current: uploads?.page,
        pageSize: uploads?.per_page,
        total: uploads?.total_count,
        style: { position: "sticky" },
      });
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  };
  

  useEffect(() => {
    const handleResize = () => {
      // Calculate 70% of the viewport height
      const height = window.innerHeight * 0.595;
      setTableHeight(height);
    };

    // Set the initial height
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleDownloadClick = async (id: string) => {
    setButtonLoading(true);
    try {
      const response = await GetDownloadsLink(id, user?.user_id); // Call the API to download the file
      window.location.href = response.url;
      console.log();
      setButtonLoading(false);
    } catch (error) {
      setButtonLoading(false);
      throwError("unable to get Download url");
    }
  };

  useEffect(() => {
    fetchData(pagination.current, pagination.pageSize);
  }, []);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    fetchData(pagination.current, pagination.pageSize);
  };

  const columns : ColumnType<DataItem>[]= [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      align:'right',
      render: (text: any, record: DataItem, index: number) => (
        <div style={{ textAlign: "right" }}>{record.serialNo}</div>
      ),
      width:'8%',
    },
    // {
    //   title: "Transaction ID",
    //   dataIndex: "transactionId",
    //   key: "transactionId",
    // },
    {
      title: "File Count",
      dataIndex: "fileCount",
      key: "fileCount",
      render: (text: string) => <div style={{ textAlign: "right" }}>{text}</div>,
      width:'10%',
      align:'right',
    },
    {
      title: "Processing",
      dataIndex: "running",
      key: "running",
      render: (text: string) => <div style={{ textAlign: "right" }}>{text}</div>,
      width:'10%',
      align:'right',
    },
    {
      title: "Completed",
      dataIndex: "completed",
      key: "completed",
      render: (text: string) => <div style={{ textAlign: "right" }}>{text}</div>,
      width:'10%',
      align:'right',

    },
    {
      title: "Incomplete",
      dataIndex: "discarded",
      key: "discarded",
      render: (text: string) => <div style={{ textAlign: "right" }}>{text}</div>,
      width:'10%',
      align:'right',
    },
    
    {
      title: "Date/Time",
      dataIndex: "dateTime",
      key: "dateTime",
      sorter: (a: DataItem, b: DataItem) =>
        new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime(),
    },
    {
      title: "Preview",
      dataIndex: "resultPreview",
      key: "resultPreview",
      width:'12%',

    },
    {
      title: "CSV URL",
      dataIndex: "resultLink",
      key: "resultLink",
    },
  ];

  return (
    <div className="w-full">
      {error ? (
        <div className="flex flex-col h-full w-full mt-60 justify-center items-center">
          <Empty description="Unable to fetch data, please try again..." />
        </div>
      ) : (
        <>
          <Table
          title={()=><h2 style={{ textAlign: 'left',fontWeight:600 ,fontSize:'15px',paddingTop:'4px',paddingBottom:'4px',paddingLeft:'2px'}}>Uploaded resume records</h2>}
            dataSource={data}
            columns={columns}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: pagination.total,
              style: { position: "sticky" },
            }}
            onChange={handleTableChange}
            loading={loading}
            bordered
            className="custom-table px-3 overflow-y-scroll"
            scroll={{ y: tableHeight }}
            size="small"
            style={{ minHeight: tableHeight }}
          />
          <Modal
  title={<span style={{ fontSize: '16px',fontWeight:'600' }}>Preview <span style={{  fontSize: '14px',fontWeight:'400' }}>(Previewing up to 5 uploaded files)</span></span>}
  open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            width={1400}
            okText="Download"
            cancelText="Close"
          >
            {tableDataLoading ? (
              <div className="items-center justify-center flex min-h-80">
              <Spin tip="Loading data...." size="large"/>
              </div>
            ) : (
              modalContent && (
                <div className="mt-8">
              <PublicConversionTable data={modalContent} column={modalTableHeader}/>
              </div>)
            )}
          </Modal>
        </>
      )}
    </div>
  );
};

export default HistoryMiddleContent;
