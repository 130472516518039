import { Table, Tooltip } from "antd";
import React from "react";
interface PublicConversionTableProps{
    data:any
    column:any
}

// Function to dynamically generate columns based on keys in data
const generateColumns = (keys: string[]) => {
  return keys.map((key) => ({
    title: key,
    dataIndex: key,
    key: key,
    width: 200,
    //   minWidth:250,
    //   maxWidth:250,
   
    render: (text: string | string[]) => {
        if (Array.isArray(text)) {
          // If it's an array, join the elements into a string
          text = text.join(', ');
        }
        // Truncate the text if it's longer than 20 characters
        const truncatedText = text?.length > 20 ? `${text.slice(0, 20)}...` : text;
        const truncatedTooltipText = text?.length > 200 ? `${text.slice(0, 200)}...` : text;

        return <Tooltip title={truncatedTooltipText}><div>{truncatedText}</div></Tooltip>;
      },
    }));
  };
  

function PublicConversionTable(props:PublicConversionTableProps){
    const columns = generateColumns(props.column);
  const dataSource = props.data;
  columns.forEach((column) => {
    column.width = 200;
});

  
    return(
        
        <Table
        columns={columns}
        dataSource={dataSource}
        style={{minWidth:800,overflow:'scroll',minHeight:'300',margin:0,padding:0}}
        size="large"
        bordered
        pagination={false}
        scroll={{x:1300}}
        />
    );
}
export default PublicConversionTable;