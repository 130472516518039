import { useEffect, useRef, useState } from "react";
import MultipleFile from "../images/Resume parser(1).png";
import pdf from "../images/singlepdf.png";
import threefile from "../images/Threefile.png";

import {
  InfoCircleOutlined,
  DesktopOutlined,
  DeleteOutlined,
  RocketOutlined,
  ReloadOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, Empty, Progress } from "antd";
import { IntegrateGoogle, UploadDiectory } from "../api";
import { throwError } from "../common/throwmessage";
import SignUp from "../pages/SinUp";
import DriveIcon from "../Icon/DriveComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { setUser } from "../store/userSlice";
import { useDispatch } from "react-redux";
import PublicConversionTable from "./PublicConversionTable";
import ConvertResumeSteps from "./ConvertResumeSteps";
import { FILE_SIZE_LIMIT } from "../Constant";

function ConversionWizardMiddlePublicpage() {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [dragging, setDragging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [uploadstatus, setUploadstatus] = useState<any>("normal");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [previewTableData, setPreviewTableData] = useState<any>([]);
  const [previewTableHeader, setPreviewTableHeader] = useState<any>([]);
  const [downloadVisible, setDownloadVisible] = useState(false); // State to manage download button visibility
  const [showSignUp, setShowSignUp] = useState(false); // State to manage component display
  const [file, setFile] = useState<FormData>(new FormData()); // State to manage component display
  const [downloadUrl, setDownloadUrl] = useState("");

  // const dropZone = document.getElementById("drop-zone");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Function to handle drag leave event
  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");

    if (code) {
      handleResponse(code);
    }
  }, []);

  const handleResponse = async (tokenResponse: any) => {
    try {
      const user = await IntegrateGoogle(tokenResponse);

      dispatch(setUser(user));
      navigate("/conversion-wizard/inside");
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    let validFileType = false;
    let validFiles = [];

    for (let i = 0; i < files.length; i++) {
      if (isFileTypeValid(files[i].type)) {
        validFileType = true;
        if (files[i].size <= FILE_SIZE_LIMIT) {
          validFiles.push(files[i]);
        } else {
          throwError("File size exceeds the limit of 5 MB.");
        }
        break;
      } else {
        throwError(
          "File format is not supported ,please provide correct file format"
        );
      }
    }
    setDragging(validFileType);
    if (validFiles.length > 0) {
      const formData = new FormData();
      validFiles.forEach((file: any) => {
        formData.append(`files`, file);
      });
      setSelectedFiles(validFiles);
      setDragging(validFileType);
      setFile(formData);
    }
    setDragging(validFileType);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    let validFileType = false;

    for (let i = 0; i < e.dataTransfer.items.length; i++) {
      if (isFileTypeValid(e.dataTransfer.items[i].type)) {
        validFileType = true;
        break;
      }
    }

    setDragging(validFileType);
  };

  const isFileTypeValid = (fileType: string) => {
    return (
      fileType === "application/pdf" ||
      fileType === "application/msword" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      fileType === "text/plain"
    );
  };

  // Function to handle file change event

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      let validFileArray = [...selectedFiles];

      for (let i = 0; i < files?.length; i++) {
        if (isFileTypeValid(files[i].type)) {
          if (files[i].size <= FILE_SIZE_LIMIT) {
            validFileArray.push(files[i]);
            setSelectedFiles(validFileArray);
          } else {
            throwError("File size exceeds the limit of 5 MB.");
          }
        } else {
          throwError("File format is not supported");
        }
        if (validFileArray.length > 0) {
          const formData = new FormData();
          validFileArray.forEach((file) => {
            formData.append(`files`, file);
          });
          setSelectedFiles(validFileArray);
          try {
            setFile(formData);
          } catch {
            setUploading(false);
            setDownloadVisible(false);
          }
        }
      }
    }
  };
  const handleDeleteFiles = () => {
    setSelectedFiles([]);
    setUploading(false);
    setDownloadVisible(false);
  };

  const handleConvert = () => {
    if (selectedFiles.length > 1) {
      handleGoogleDriveClick();
    } else {
      setUploading(true);
      setUploadstatus("active");
      try {
        UploadDiectory(
          "test",
          "64196429-e49c-48fb-bf63-1320da90c978",
          undefined,
          file,
          (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          }
        )
          .then((response) => {
            setPreviewTableData(response.metadata);
            setPreviewTableHeader(response?.headers)
            const downloadUrl = response.url;
            setDownloadUrl(downloadUrl);
            setUploading(false);
            setUploadstatus("success");
            setDownloadVisible(true);
          })
          .catch(() => {
            setUploadstatus("exception");
            setUploading(false);
            setDownloadVisible(false);
          });
      } catch {
        throwError("Unable to convert");
      }
    }
  };

  const handleGoogleDriveClick = () => {
    setShowSignUp(true);
  };

  return (
    <>
      {showSignUp && <SignUp />}

      <div className="flex flex-col overflow-y-scroll mt-12">
        {selectedFiles.length === 0 &&
          !downloadVisible &&
          !uploading &&
          !showSignUp && (
            <><div className="p-4 flex flex-col items-center">
            <div className="items-center text-4xl font-bold mb-2 ">
              Transform Resumes to Excel{" "}
            </div>
            <div className="items-center p-4 text-xl font-medium mb-12">
              Transform Resumes with Ease: Fast, Accurate, and Reliable PDF to
              Excel conversion
            </div>

            <div
              className="file-selection p-4 flex flex-col items-center w-full sm:max-w-6xl max-w-sm bg-white border border-dashed justify-center border-opacity-20 mb-4"
              style={{ backgroundColor: "#EEF7FF" }}
            >
              <div
                className="flex w-full  rounded-md pt-4 pl-4 pr-4 "
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                id="drop-zone"
                style={{ height: "40%" }}
              >
                <div className="w-full h-full">
                  <div
                    className="w-full h-full flex items-center justify-center"
                    tabIndex={0}
                    role="button"
                    onClick={() => fileInputRef.current?.click()}
                    onKeyDown={() => { } }
                  >
                    <div className="flex flex-col justify-center items-center space-y-2">
                      <div>
                        <i
                          className="fa fa-cloud-upload text-5xl text-opacity-30"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="mt-4 w-full">
                        <img
                          src={MultipleFile}
                          alt="Your description"
                          className="w-40 h-50 object-cover"
                          style={{ width: "400px" }} />
                      </div>
                      <div className="text-opacity-50">
                        {dragging
                          ? "Drop that file down low"
                          : "Drag and drop a file for single resume "}
                        <br />
                        {dragging ? (
                          "Drop that file down low"
                        ) : (
                          <InfoCircleOutlined />
                        )}
                        {dragging
                          ? "Drop that file down low"
                          : " Max file size 5 MB"}
                      </div>
                      <div className="flex items-center justify-center  text-opacity-25 space-x-2">
                        <div>
                          <hr className="w-16 border border-opacity-30" />
                        </div>
                        <div className=" text-opacity-40">or</div>
                        <div>
                          <hr className="w-16 border border-opacity-30" />
                        </div>
                      </div>
                      <div className="">
                        <input
                          type="file"
                          ref={fileInputRef}
                          id="FileInput"
                          data-testid="input-attachment"
                          style={{ display: "none" }}
                          onChange={(event) => handleFileChange(event)}
                          accept=".pdf, .doc, .docx, .txt,"
                          // webkitdirectory={webkitdirectory ? true : undefined}
                          // mozdirectory={mozdirectory ? true : undefined}
                          multiple />
                        <Button
                          // onClick={() => fileInputRef.current?.click()}
                          icon={<DesktopOutlined />}
                          type="primary"
                          style={{ width: "280px" }}
                        >
                          Select Files
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* )} */}
                </div>
              </div>
              <div className="mt-4 flex flex-col items-center space-y-2 pb-6 ">
                <Button
                  onClick={handleGoogleDriveClick}
                  icon={<DriveIcon />}
                  style={{ width:'280px'}}
                  className="flex items-center justify-center"
                  disabled={false}
                >
                  Google Drive
                </Button>
              </div>
            </div>
            <ConvertResumeSteps />
          </div></>
          )}

        {uploading && (
          //   <div
          //   className="file-selection p-4 flex flex-col items-center bg-white border border-dashed justify-center border-opacity-20 mb-4"
          //   style={{ backgroundColor: "#EEF7FF" }}
          // >
          <div
            className="flex flex-col items-center px-12  justify-center m-12 pt-48 pb-52"
            style={{ backgroundColor: "#EEF7FF" }}
          >
            <div className="mt-4 w-full mb-4">
              <Progress
                type="circle"
                percent={uploadProgress}
                status={uploadstatus}
              />
            </div>
            <div>Stay patient....</div>
            <div> We are processing your resume</div>
            {/* <div>It may take some time</div> */}
          </div>
          // </div>
        )}
        {selectedFiles.length > 0 && !downloadVisible && !uploading && (
          <div
            className="flex flex-col items-center px-4 pt-32 pb-64 m-4 mx-12 "
            style={{ backgroundColor: "#EEF7FF", height: "80%" }}
          >
            <div className="mt-4 text-tacitbase-tertiary1 text-opacity-50 flex flex-col items-center justify-center pb-4">
              {selectedFiles.length === 1 ? (
                <img src={pdf} alt="Your description" className="p-4" />
              ) : (
                <img src={threefile} alt="Your description" className="p-4" />
              )}
              {selectedFiles.length === 1
                ? `Selected file: ${selectedFiles[0].name}`
                : `${selectedFiles.length} files selected`}
            </div>
            <div className="flex flex-row space-x-4">
              <Button
                type="default"
                onClick={handleDeleteFiles}
                style={{ paddingTop: "4px", paddingBottom: "4px" }}
                icon={<DeleteOutlined />}
              >
                Cancel
              </Button>
              {selectedFiles.length === 1 ? (
                <Button
                  type="primary"
                  icon={<RocketOutlined />}
                  onClick={handleConvert}
                  style={{ paddingTop: "4px", paddingBottom: "4px" }}
                >
                  Convert{" "}
                </Button>
              ) : (
                <Button
                  type="primary"
                  // icon={<RocketOutlined />}
                  // onClick={handleGoogleDriveClick}
                  href="/sign-up"
                  style={{ paddingTop: "4px", paddingBottom: "4px" }}
                >
                  Sign In{" "}
                </Button>
              )}
            </div>
          </div>
        )}
        {downloadVisible && !uploading && selectedFiles.length > 0 && (
          <div
            className="flex flex-col items-center  m-8 p-4 pt-20 pb-60  "
            style={{ height: "80%" }}
          >
            {/* <div className="mt-4 text-tacitbase-tertiary1 text-opacity-50 flex flex-col items-center justify-center pb-4">
              <img src={singlexls} alt="Your description" className="p-4" />
            </div> */}
            {previewTableData[0].this_document_is_resume === true ? (
              <><PublicConversionTable data={previewTableData} column={previewTableHeader}/><div className="flex flex-row space-x-4 mt-12 justify-start">
                <Button
                  type="default"
                  onClick={handleDeleteFiles}
                  style={{ paddingTop: "4px", paddingBottom: "4px" }}
                  icon={<ReloadOutlined />}
                >
                  Start again
                </Button>
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  style={{ paddingTop: "4px", paddingBottom: "4px" }}
                  // href={downloadUrl}
                  // onClick={handleDeleteFiles}
                  onClick={() => {
                    // Perform the download action
                    setDownloadLoading(true);
                    window.location.href = downloadUrl;

                    // Perform the delete files action
                    setTimeout(handleDeleteFiles, 1000);
                    setDownloadLoading(false);
                  } }
                  loading={downloadLoading}
                >
                  Download
                </Button>
              </div></>
            ) : (
              <>
                <div
                  className=" flex flex-col px-4 py-28 border border-dashed  border-opacity-20 w-10/12"
                  style={{ backgroundColor: "#EEF7FF" }}
                >
                  <div
                    style={{ marginBottom: 10 }}
                    className="items-center justify-center "
                  >
                    <Empty
                      image={
                        <ExclamationCircleOutlined
                          style={{
                            fontSize: 80,
                            color: "#FFC53D",
                            marginBottom: "0",
                          }}
                        />
                      }
                      description={
                        <div
                          className="flex flex-col items-center"
                          style={{ color: "#626161" }}
                        >
                          <div>This doesn&apos;t seem to be a resume,</div>
                          <div>Please upload your correct resume again.</div>
                        </div>
                        
                      }
                    />
                  </div>
                </div>
                <div className=" mt-4 space-x-2 flex flex-row justify-end">
                <Button
                  type="primary"
                  onClick={handleDeleteFiles}
                  style={{ paddingTop: "4px", paddingBottom: "4px" }}
                  icon={<ReloadOutlined />}
                >
                  Start again
                </Button>
                </div>
              </>
            )}

            
            {/* <div className="p-4"> */}
            {/* </div> */}
          </div>
        )}
      </div>
    </>

    // </div>
  );
}
export default ConversionWizardMiddlePublicpage;
