import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store'; // Adjust the path if necessary
import LoggedInLayout from './LoggedInLayout';
import LoggedOutLayout from './LoggedOutLayout';
import ConversionWizardMiddlePublicpage from '../components/ConversionWizardPublic';

const ResumeParserLayout = (children:any ) => {
  
  const user = useSelector((state: RootState) => state.user.user);
  return (
    <div>
      {user ? <LoggedInLayout>{children}</LoggedInLayout> : <LoggedOutLayout><ConversionWizardMiddlePublicpage/></LoggedOutLayout>}
    </div>
  );
};

export default ResumeParserLayout;
