import SignInWithGoogleButton from "./GoogleLogin";

function SignupMiddlepage(){
    return(
        <div className="flex flex-col">
        <div className=" flex text-center text-2xl font-bold justify-center pl-12 pt-6 pr-12 pb-10" > Sign Up</div>
        <div className="flex px-6 pb-3 text-base font-semibold items-start">AI-Powerd Resume Parsing</div>
        <div className="flex px-6 py-2 justify-start text-left">Experience next-level accuracy in resume-to-excel translations</div>
        <div className="flex px-6 py-2 w-full"> <SignInWithGoogleButton/></div>
        <div className="flex px-6 py-2 items-start">Having trouble connecting?<a href="mailto:support@resumeparser.in?subject=I Need Help with Resume Parsing"  style={{ color: "#009D79" }}
>Contact us.</a></div>
        <div className="flex  mx-5 pb-6 pt-12 items-start justify-start">
          <div className="text-left">
            By signing up, you agree to our&nbsp;
            <a
           
              href="/terms-of-use"
              style={{ color: "#009D79" }}
            >
              terms of use{" "}
            </a>
            &nbsp;and&nbsp;
            <a
              href="/privacy-policy"
              style={{ color: "#009D79" }}
            >
              {" "}
              privacy policy.
            </a>
            &nbsp;Need help? Visit&nbsp;
            <a
              href="mailto:support@resumeparser.in?subject=I Need Help with Resume Parsing"
              style={{ color: "#009D79" }}
            >
              support.
            </a>
          </div>
        </div>

     </div>
    );
}
export default SignupMiddlepage;