import { Layout } from "antd";
import ConversionWizardMiddlepage from "../components/ConversionWizardMiddlepage";
import ConversionWizardHeader from "../components/ConversionWizardHeader";

function ConversionWizardPage() {
  return (
    <>
      <ConversionWizardHeader />

      <Layout className="p-4 h-screen items-center justify-center overflow-y-scroll">
        <ConversionWizardMiddlepage />
      </Layout>
    </>
  );
}
export default ConversionWizardPage;
