import React from "react";
import TopBubble from "../common/TopBubble";
import BottomBubble from "../common/BottomBubble";
import ProductName from "../common/ProductName";
const PageNotFound = () => {
  document.title = "Resume parser";
  return (
    <>
      {" "}
      <div className="flex  flex-col justify-center items-center h-screen">
        <TopBubble />
        <div className="flex flex-col justify-center items-center space-x-4 ">
          {" "}
          <ProductName /> <h1 className="text-7xl font-bold tracking-widest h-32">404</h1>
          <h1 className="tracking-widest text-xl font-semibold">O-o-oh!</h1>{" "}
          <h1 className="tracking-wider text-xl font-semibold">The page you are looking for is not here</h1>
        </div>{" "}
        <BottomBubble />
      </div>{" "}
    </>
  );
};
export default PageNotFound;
