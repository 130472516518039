import React from 'react'
import PublicHeader from "../common/PublicHeader";
import TermsOfUse from "../common/TermsOfUse";
import { Layout } from 'antd';

function TermsOfUsePage(){
    return(
        <Layout className="h-screen">
        <div className="h-full w-full overflow-hidden bg-white">
            
            <PublicHeader/>
        
           
                   <div className='flex h-full items-center w-full overflow-y-scroll justify-center'>
                      <TermsOfUse />
                      </div>
                    </div>
                  
               
        
       
        </Layout>
    )
}
export default TermsOfUsePage;