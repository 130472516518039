import axios, { AxiosError, AxiosInstance } from "axios";
import throwApiError, { throwError } from "./common/throwmessage";

let api: AxiosInstance;
const epResume = "/v1/resume/parsing/";


interface IntegrateGoogleUser {
    id: string;
    client_id: string;
    access_token: string;
    refresh_token: string;
    expires_at: string; // This should be a date or timestamp type ideally
    user_id: string;
    email: string;
    name: string;
    profile: string;
    created_at: string; // This should be a date or timestamp type ideally
    updated_at: string; // This should be a date or timestamp type ideally
}

export const configureAPI = (baseURL: string | undefined) => {
  api = axios.create({ baseURL: baseURL });
};

export const GetGoogleDriveDiectory = (data_source:string,identifier:string,pagesize:number,next_token?:string,search_key?:string) => {
    let ep = `v1/resume/parsing/resumeprs/dirs?data_source=${data_source}&identifire=${identifier}&search_key=${search_key}&pz=${pagesize}&nt=${next_token}`;  
    return new Promise<any>((resolve, reject) => {
      api
        .get<any>(ep)
        .then((res: any) => {                
          if (res.status === 200 ) {
            resolve(res.data);
          } else {
            throwError("invalid credentials");
          }
        })
        .catch((reason: AxiosError|any) => {
          throwApiError(reason);
          reject(reason);
        });
    });
  };


  export const IntegrateGoogle = (code:any) => {
    let ep = epResume +`oauth/exchange?code=${code}`;
        return new Promise<any>((resolve, reject) => {
      api
        .get<any>(ep)
        .then((res: any) => {                
          if (res.status === 200 ) {
            resolve(res.data.user);
          } else {
            throwError("invalid credentials");
          }
        })
        // .catch((reason: AxiosError|any) => {
        //   throwApiError(reason);
        //   reject(reason);
        // });
    });
  };

  export const UploadDiectory = (data_source:string,identifier:string,dir_id?:string,formdata?:any,onUploadProgress?: (progressEvent: any) => void,onDownloadProgress?:(progressEvent:any)=>void) => {
    let ep = epResume+`resumeprs/uploads?data_source=${data_source}&identifire=${identifier}&resp_type=csv`

    if (dir_id) {
        ep += `&dir=${dir_id}`;
      }
        return new Promise<any>((resolve, reject) => {
      api
        .post<any>(ep,formdata,
          
          {      
            headers: {
              'Content-Type': 'multipart/form-data', // Ensure this is set correctly
            },
            onUploadProgress,
            onDownloadProgress
        })
        .then((res: any) => {                
          if (res.status === 200 ) {
            resolve(res.data.uploads);
          } 
        })
        .catch((reason: AxiosError|any) => {
          throwApiError(reason);
          reject(reason);
        });
    });
  };


  export const GetUploadDiectory = (page:number,per_page:number,identifier:string,) => {
    let ep = `v1/resume/parsing/resumeprs/uploads?page=${page}&per_page=${per_page}&identifire=${identifier}`
        return new Promise<any>((resolve, reject) => {
      api
        .get<any>(ep)
        .then((res: any) => {                
          if (res.status === 200 || res.status === 201) {
            resolve(res.data);
          } else {
            throwError("invalid credentials");
          }
        })
        .catch((reason: AxiosError|any) => {
          throwApiError(reason);
          reject(reason);
        });
    });
  };


  export const GetPaymentPlans = () => {
    let ep = epResume+`billing/plans`
            return new Promise<any>((resolve, reject) => {
      api
        .get<any>(ep)
        .then((res: any) => {                
          if (res.status === 200 ) {
            resolve(res.data.products);
          } else {
            throwError("invalid plans");
          }
        })
        .catch((reason: AxiosError|any) => {
          throwApiError(reason);
          reject(reason);
        });
    });
  };


  export const GetCheckoutSessions = (success_url:string,identifier:string) => {
    let ep = epResume+`billing/checkouts?success_url=${success_url}&identifier=${identifier}`
            return new Promise<any>((resolve, reject) => {
      api
        .get<any>(ep)
        .then((res: any) => {                
          if (res.status === 200 ) {
            resolve(res.data.sessions);
          } 
        })
        .catch((reason: AxiosError|any) => {
          throwApiError(reason);
          reject(reason);
        });
    });
  };



  export const GetHistoryTransactionsTableDetails = (page:number,per_page:number,identifier:string) => {
    let ep = epResume+`resumeprs/uploads?page=${page}&per_page=${per_page}&identifire=${identifier}`
                return new Promise<any>((resolve, reject) => {
      api
        .get<any>(ep)
        .then((res: any) => {                
          if (res.status === 200 ) {
            resolve(res.data.uploads);
          } else {
            throwError("invalid plans");
          }
        })
        .catch((reason: AxiosError|any) => {
          throwApiError(reason);
          reject(reason);
        });
    });
  };


  export const GetDownloadsLink = (tran_id:string,identifier:string) => {
    let ep = epResume+`resumeprs/uploads/transation?tran_id=${tran_id}&identifire=${identifier}`
   return new Promise<any>((resolve, reject) => {
      api
        .get<any>(ep)
        .then((res: any) => {                
          if (res.status === 200 ) {
            resolve(res.data.uploads);
          } else {
            throwError("invalid plans");
          }
        })
        .catch((reason: AxiosError|any) => {
          throwApiError(reason);
          reject(reason);
        });
    });
  };


  export const GetGoogleAccessLink = () => {
    let ep = epResume+`oauth/accessurl`
   return new Promise<any>((resolve, reject) => {
      api
        .get<any>(ep)
        .then((res: any) => {                
          if (res.status === 200 ) {
            resolve(res.data.oauth);
          }
          //  else {
          //   throwError("Invalid google url");
          // }
        })
        .catch((reason: AxiosError|any) => {
          throwApiError(reason);
          reject(reason);
        });
    });
  };


  export const GetUserCredits = (identifier:string) => {
    let ep = epResume+`billing/credits?identifier=${identifier}`
            return new Promise<any>((resolve, reject) => {
      api
        .get<any>(ep)
        .then((res: any) => {                
          if (res.status === 200 ) {
            resolve(res.data.subscriptions);
          } else {
            throwError("invalid plans");
          }
        })
        .catch((reason: AxiosError|any) => {
          console.log(reason)

          throwApiError(reason);
          reject(reason);
        });
    });
  };