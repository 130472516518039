import React from 'react';
import { Collapse } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import './FAQuestion.css'

const { Panel } = Collapse;

const faqData = [
  {
    question: "What file formats does your tool support?",
    answer: "Our tool supports a wide range of file formats commonly used for resumes, including PDF, Word (.doc and .docx), and Text (.txt)."
  },
  {
    question: "Do I need to install any software to use this tool?",
    answer: "No, our resume parsing tool is web-based. Simply access it through your web browser and start uploading resumes."
  },
  {
    question: "What kind of data does your tool extract from resumes?",
    answer: "Our tool extracts key information such as contact details, work experience (including company names, job titles, and dates of employment), education (including schools, degrees, and areas of study), and skills (both technical and soft skills)."
  },
  {
    question: "How accurate is the extracted data?",
    answer: "Our AI-powered parsing engine delivers exceptional accuracy. However, due to variations in resume formats, occasional manual verification might be required."
  },
  {
    question: "What can I do with the exported CSV file?",
    answer: "The exported CSV file is compatible with all major spreadsheet software programs like Microsoft Excel or Google Sheets. You can use it to filter, analyze, and sort candidate data for efficient hiring decisions."
  },
  {
    question: "How long does it take to parse a resume?",
    answer: "The parsing process is very fast. Typically, it takes just a few seconds to extract data from a resume."
  },
  {
    question: "What happens if the parsing fails for a resume?",
    answer: "In rare cases, the parsing might not be perfect due to unusual formatting. Our system will notify you of any potential issues, and you can choose to manually review the data or upload a corrected version of the resume."
  },
  {
    question: "Do you offer a free trial?",
    answer: "We offer a generous free plan that allows you to parse up to 50 resumes without creating an account. This gives you ample opportunity to experience the tool's capabilities and see how it can streamline your hiring process."
  },
  {
    question: "What happens after the free trial?",
    answer: "After parsing your 50 free resumes, you can continue using the tool by signing up for one of our paid plans. We offer various plans with different parsing limits to suit your needs. You can find more details and pricing options on our pricing page."
  }
];

const CustomIcon = ({ isActive }: { isActive: boolean|any }) => (
  isActive ? <MinusOutlined /> : <PlusOutlined />
);

const FAQCollapse = () => (
  <div className="items-center justify-center sm:ml-24" style={{ padding: '20px', textAlign: 'center' }}>
    <Collapse
      accordion
      expandIconPosition="right" 
      bordered={false}
      expandIcon={({ isActive }) => <CustomIcon isActive={isActive} />}
      style={{backgroundColor:'white',padding:'8px',paddingBottom:'16px',paddingTop:'16px',textAlign:'left',fontSize:'18px',fontWeight:'600'}}
      className='custom-collapse'
    >
      {faqData.map((item, index) => (
        <Panel header={item.question} key={index}>
          <p  style={{textAlign:'left',fontSize:'15px',color:'#323232',fontWeight:'500'}}>{item.answer}</p>
        </Panel>
      ))}
    </Collapse>
  </div>
);

export default FAQCollapse;
