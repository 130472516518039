export const capitalizeFirstLetter = (word:string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };


  export const FILE_SIZE_LIMIT = 5*1048576; // 5 MB in bytes

  export const FREE_RESUME_PARSE_FILE_COUNT = 3;

  export const BILLING_PRICE= 19.99;

//  export  const isValidImageExtension = (url: string | any) => {
//     const validExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
//     const urlExtension = url.split(".").pop().toLowerCase();
//     return validExtensions.includes(urlExtension);
//   };
  

  