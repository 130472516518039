import Layout, { Content } from "antd/es/layout/layout";
import { ReactNode } from "react";
import PublicHeader from "./PublicHeader";

interface LoggedOutLayoutProps{
    children:ReactNode
}

// const contentStyle: React.CSSProperties = {
//     textAlign: 'center',
//   minHeight:'calc(100vh-64px )',
//   backgroundColor:'white'
  
//   };

  const layoutStyle: React.CSSProperties = {
    overflow: 'hidden',
    width: 'calc(100%)',
    maxWidth: 'calc(100% )',
    position:'fixed'
  };

function LoggedOutLayout(Props:LoggedOutLayoutProps){
    return(
<Layout style={{height: '100vh',...layoutStyle}}>
    <PublicHeader/>
    {/* <Layout > */}
    <Content style={{backgroundColor:'white',alignItems:'center',justifyContent:'center',paddingBottom:'4px',overflowY:'scroll'}} >
          {Props.children}  
    </Content>
    {/* </Layout> */}
</Layout>
    );
}
export default LoggedOutLayout;