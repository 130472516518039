import React from 'react';
import { Tabs } from 'antd';
import './FAQuestion.css'


const { TabPane } = Tabs;

// Define the type for the keys of tabContents
type TabKeys = 'Effortless Data Extraction' | 'Boost Your Hiring Process' | 'Unmatched Accuracy' | 'CSV Download' | 'Faster Candidate Sorting' | 'Improve Hiring Decisions';

const tabContents: Record<TabKeys, string> = {
  'Effortless Data Extraction': 'Extract skills, experience, education, and more from resumes in seconds.',
  'Boost Your Hiring Process': 'Spend less time sorting and more time interviewing qualified candidates.',
  'Unmatched Accuracy': 'Our AI-powered parsing ensures precise data extraction, minimizing errors.',
  'CSV Download': 'Easily export extracted data into clean, well-organized CSV files for seamless integration with your ATS.',
  'Faster Candidate Sorting': 'Identify top talent quickly by filtering candidates based on extracted skills and experience.',
  'Improve Hiring Decisions': 'Make data-driven decisions with all the relevant candidate information at your fingertips.'
};

const TabContent = ({ content }: { content: string }) => (
  <div className='tab-content flex items-center justify-center' style={{ padding: '40px',paddingTop:'60px',paddingBottom:'60px' , backgroundColor: '#e6fffb', borderRadius: '4px' ,fontSize:'24px' ,fontWeight:800,minHeight:'300px'}}>
    {content}
  </div>
);

const MyTabs = () => (
  <div style={{ padding: '20px',textAlign: 'center' }}>
    <Tabs defaultActiveKey="1" centered tabBarGutter={8} type="card" tabPosition='left' className='sm:max-w-6xl max-w-80'>
      {Object.keys(tabContents).map((key, index) => (
        <TabPane tab={key} key={index + 1}>
          <TabContent content={tabContents[key as TabKeys]} />
        </TabPane>
      ))}
    </Tabs>
    
  </div>
);

export default MyTabs;
