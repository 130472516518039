import React, { useEffect, useState } from "react";
import { Layout, Menu,} from "antd";
import { HomeOutlined, DoubleLeftOutlined,HistoryOutlined  } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";

const { Sider } = Layout;


interface SidenavProps {
  setActiveTab: (key: string) => void;
}

const Sidenav: React.FC<SidenavProps> = ({ setActiveTab }) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
    // window.location.href = "https://calendar.app.google/9GxWM6XdbvdECJMw9";
    };

  // const handleMenuClick = (key: string) => {
  //   setActiveTab(key);
  // };
  const getMenuKey = (path: string) => {
    switch (path) {
      case "/conversion-wizard/inside":
        return "1";
      case "/history":
        return "2";
      default:
        return "1";
    }
  };

  useEffect(() => {
    setActiveTab(getMenuKey(location.pathname));
  }, [location.pathname, setActiveTab]);


  return (
    <Sider
      collapsible
      collapsed={collapsed}
      trigger={null}
      style={{ minHeight: "92vh", backgroundColor: "white" }}
      className="justify-between "
    >
      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={[getMenuKey(location.pathname)]}
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Menu.Item
          key="1"
          icon={<HomeOutlined />}
        //   onClick={() => handleMenuClick("1")}
          style={{ textAlign: "left" }}
        >
     <Link to="/conversion-wizard/inside" onClick={() => setActiveTab("1")}>

          Conversion Wizard
          </Link>
        </Menu.Item>
        <Menu.Item
          key="2"
          icon={<HistoryOutlined />}
        //   onClick={() => handleMenuClick("2")}
          style={{ textAlign: "left" }}
        >
        <Link to="/history" onClick={() => setActiveTab("2")}>

          History
          </Link>
        </Menu.Item>
        <div style={{ flex: 1 }}></div>{" "}
        {/* Add a flexible space to push the last item to the bottom */}
        <Menu.Item
          key="3"
          // icon={collapsed ? <DoubleLeftOutlined /> : <DoubleLeftOutlined />}
          onClick={toggleCollapse}
          style={{ textAlign: "center"}}
        >
         {collapsed ? <DoubleLeftOutlined /> : <DoubleLeftOutlined />}
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidenav;
