import {Layout } from "antd";
import SignupMiddlepage from "../components/SinupMiddlepage";
import PublicHeader from "../common/PublicHeader";

function SignUpWithHeader(){
return(


    <Layout className="h-screen">
<div className="h-full w-full overflow-hidden bg-white">
    
    <PublicHeader/>

   
            <div className="flex  h-full items-center justify-center  xl:space-x-4" >
          <div className=" flex  justify-center h-full items-center w-full overflow-y-scroll">
            <div
              className="bg-white     xl:p-8 p-4 rounded xl:m-10 m-4 "
              style={{
                width: 432,
                boxShadow: "2px 4px 10px 0px #00000040",
                border: "1px solid #D9D9D9",
              }}
            >
              <SignupMiddlepage />
            </div>
          </div>
        </div>   

</div>
</Layout>
);

}
export default SignUpWithHeader;