import { useRef, useState } from "react";
import MultipleFile from "../images/Resume parser(1).png";
import pdf from "../images/singlepdf.png";
import threefile from "../images/Threefile.png";
import singlexls from "../images/singlexls.png";

import {
  InfoCircleOutlined,
  DesktopOutlined,
  DeleteOutlined,
  RocketOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Button, Progress, message } from "antd";
import { throwError } from "../common/throwmessage";
import GooglePicker from "./GooglePicker";
import BillingPlanModal from "./BillingPlanModal";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { UploadDiectory } from "../api";
import { FILE_SIZE_LIMIT, capitalizeFirstLetter } from "../Constant";

function ConversionWizardMiddlepage() {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [dragging, setDragging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadstatus, setUploadstatus] = useState<any>("normal");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [downloadVisible, setDownloadVisible] = useState(false); 
  const [uploadSelectionState, setUploadSelectionState] = useState(false); 
  const [file, setFile] = useState<FormData>(new FormData()); 
  const [folderFileCount, setFolderFileCount] = useState<any>(0);


  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user.user);


  const uploadDirectoryProgress = async (
    data_source: string,
    identifier: string,
    dir_id: any,
    formData: any
  ) => {
    try {

      setUploading(true);
      setUploadstatus("active");

      await UploadDiectory(
        data_source,
        identifier,
        dir_id,
        formData,
        (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        }
      );
      setUploading(false);
      setDownloadVisible(true);

    } catch (error) {
      setUploadstatus("exception");
      setSelectedFiles([])
      setUploading(false);
      setDownloadVisible(false)
    }
  };

  //   const showModal = () => {
  //     {
  //       selectedFiles?.length > 1 ||
  //       (selectedFiles?.[0].mimeType == "application/vnd.google-apps.folder" &&
  //         selectedFiles?.length == 1)
  //         ? setIsModalOpen(true)
  //         : setIsModalOpen(false);
  //     }
  //   };

  //   const handleOk = (selectedFiles: DataType[]) => {
  //     setSelectedFiles([selectedFiles]);
  //     console.log(selectedFiles, "table files");
  //   };
  //   console.log(selectedFiles?.[0]?.type,"folder type")

  const handleCancelilling = () => {

    setIsModalOpen(false);
  };


  // Function to handle drag leave event
  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };


  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    let validFileType = false;
    let validFiles = [];

    for (let i = 0; i < files.length; i++) {
      if (isFileTypeValid(files[i].type)) {
        validFileType = true;
        if (files[i].size <= FILE_SIZE_LIMIT) {
          if (selectedFiles?.length > user.credits) {
            throwError("You don't have enough credits to parse the resumes.");
            handleDeleteFiles()
            return; // Stop further processing
          }
          validFiles.push(files[i]);
          setUploadSelectionState(true);

        } else {
          throwError("File size exceeds the limit of 5 MB.");
        }
        break;
      } else {
        throwError(
          "File format is not supported ,please provide correct file format"
        );
      }
    }
    setDragging(validFileType);
    if (validFiles.length > 0) {
      const formData = new FormData();
      validFiles.forEach((file: any) => {
        formData.append(`files`, file);
      });
      setSelectedFiles(validFiles);
      setDragging(validFileType);
      setFile(formData);
    }
    setDragging(validFileType);
  };


  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    let validFileType = false;
    for (let i = 0; i < e.dataTransfer.items.length; i++) {
      if (isFileTypeValid(e.dataTransfer.items[i].type)) {
        validFileType = true;
        break;
      }
    }
    setDragging(validFileType);
  };

  const isFileTypeValid = (fileType: string) => {
    return (
      fileType === "application/pdf" ||
      fileType === "application/msword" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      fileType === "text/plain"
    );
  };

  // Function to handle file change event
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      let validFileArray = [...selectedFiles];

      for (let i = 0; i < files?.length; i++) {
        if (isFileTypeValid(files[i].type)) {
          if (files[i].size <= FILE_SIZE_LIMIT) {
            // Check if adding this file will exceed the user's credits
          if (selectedFiles.length + 1 > user.credits) {
            throwError("You don't have enough credits to parse the resumes.");
            handleDeleteFiles()
            // setSelectedFiles([])
            // setUploading(false);
            // setDownloadVisible(false);
            return; // Stop further processing
          }
            validFileArray.push(files[i]);
            setSelectedFiles(validFileArray);
            setUploadSelectionState(true);
          } else {
            throwError("File size exceeds the limit of 5 MB.");
          }
        } else {
          throwError("File format is not supported");
        }
        if (validFileArray.length > 0) {
          const formData = new FormData();
          validFileArray.forEach((file) => {
            formData.append(`files`, file);
          });
          setSelectedFiles(validFileArray);
          try {
            setFile(formData);
          } catch {
            setUploading(false);
            setDownloadVisible(false);
          }
        }
      }
    }
  };
  

  const handleDeleteFiles = () => {
    setSelectedFiles([]);
    setUploading(false)
    setDownloadVisible(false)
    setFolderFileCount(0);
    navigate("/conversion-wizard/inside");
  };

  // function for sending folder for conversion
  const handleConvertFolder = async () => {
   try{
    if (
      folderFileCount <= user.credits ){        
      setIsModalOpen(false);     
      const formData = new FormData();
      selectedFiles.forEach((file: any) => {
        formData.append(`file_ids`, file.id);
      });    
      uploadDirectoryProgress("gfiles",user?.user_id,selectedFiles[0]?.id,formData)
    }
    else{
      throwError("You don't have enough credits")
      setIsModalOpen(true);
      setUploading(false)
    }
  }
    catch{
      throwError("Unable to convert")
    }

  };

 
  const handleConvertFile = () => {
    
      if (
        selectedFiles?.length <= user?.credits ){
          setIsModalOpen(false);
          uploadDirectoryProgress('local',user?.user_id,undefined,file)
        }
        else{
          throwError("You don't have enough credits to parse the resumes.")
          setIsModalOpen(true);
          setUploading(false)

        }
    
         
  };

  const handleGoogleFileSelect = (file: any) => {
    try {
      if (file.action === "picked" && file?.docs?.length > 0) {
        if (file?.docs?.length > user.credits) {
          throwError("You don't have enough credits to parse the resumes.");
          handleDeleteFiles();
          return; // Stop further processing
        }
        setSelectedFiles(file?.docs);
        setFolderFileCount(file?.docs?.length);
        setUploadSelectionState(false);
        setUploading(false);
        setDownloadVisible(false);
      } else if (file.action === "picked" && file?.fileCount === 0) {
        message.error("Folder contain 0 files.");
        setSelectedFiles([]);
      } else {
        setSelectedFiles([]);
      }
    } catch {
      throwError("unable to select folder");
      setUploading(false);
      setDownloadVisible(false);
    }
  };



  return (
    <div className="h-full w-full">
      {selectedFiles?.length === 0 && !downloadVisible && !uploading &&
              folderFileCount === 0 && (
        <div className="">
          <div className="items-center text-4xl font-bold mb-2 mt-4">
            Transform Resumes to Excel{" "}
          </div>
          <div className="items-center p-4 text-xl font-medium mb-5">
            Transform Resumes with Ease:Fast, Accurate, and Reliable PDF to
            Excel conversion
          </div>

          <div
            className="flex flex-col items-center w-full  border border-dashed border-tacitbase-tertiary1 border-opacity-20 pb-16"
            style={{ backgroundColor: "#EEF7FF" }}
          >
            <div
              className="flex w-full  rounded-md pt-4 pl-4 pr-4"
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              id="drop-zone"
              //   style={{ height: "40%" }}
            >
              <div className="w-full h-full">
                <div
                  className="w-full h-full flex items-center justify-center"
                  tabIndex={0}
                  role="button"
                  onClick={() => fileInputRef.current?.click()}
                  onKeyDown={() => {}}
                >
                  <div className="flex flex-col justify-center items-center space-y-2">
                    {/* <div>
                      <i
                        className="fa fa-cloud-upload text-5xl text-tacitbase-tertiary1 text-opacity-30"
                        aria-hidden="true"
                      ></i>
                    </div> */}
                    <div className="mt-4 w-full">
                      <img
                        src={MultipleFile}
                        alt="Your description"
                        className="w-40 h-30 object-cover"
                        style={{ width: "400px" }}
                      />
                    </div>
                    <div className="text-tacitbase-tertiary1 text-opacity-50">
                      {dragging
                        ? "Drop that file down low"
                        : "Drag and drop a file for single resume "}
                      <br />
                      {dragging ? (
                        "Drop that file down low"
                      ) : (
                        <InfoCircleOutlined />
                      )}
                      {dragging
                        ? "Drop that file down low"
                        : " Max file size 5 MB"}
                    </div>
                    <div className="flex items-center justify-center text-tacitbase-tertiary1 text-opacity-25 space-x-2">
                      <div>
                        <hr className="w-16 border border-tacitbase-tertiary1 border-opacity-30" />
                      </div>
                      <div className="text-tacitbase-tertiary1 text-opacity-40">
                        or
                      </div>
                      <div>
                        <hr className="w-16 border border-tacitbase-tertiary1 border-opacity-30" />
                      </div>
                    </div>
                    <div className="">
                      <input
                        type="file"
                        ref={fileInputRef}
                        id="FileInput"
                        data-testid="input-attachment"
                        style={{ display: "none" }}
                        onChange={(event) => handleFileChange(event)}
                        accept=".pdf, .doc, .docx, .txt,"
                        // webkitdirectory={webkitdirectory ? true : undefined}
                        // mozdirectory={mozdirectory ? true : undefined}
                        multiple
                      />
                      <Button
                        // onClick={() => fileInputRef.current?.click()}
                        icon={<DesktopOutlined />}
                        type="primary"
                        style={{ width: "315px", height: "40px" }}
                      >
                        Select Files
                      </Button>
                    </div>
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>
            <div className="mt-4 flex flex-col items-center space-y-2 ">
              <GooglePicker handleSelectFiles={handleGoogleFileSelect} />
            </div>
          </div>
        </div>
      )}

      {uploading && (
        <div
          className="flex items-center px-4  pt-60 pb-80"
          style={{ backgroundColor: "#EEF7FF" }}
        >
          <div className="mt-4 w-full">
            <Progress
              type="circle"
              percent={uploadProgress}
              status={uploadstatus}
            />
          </div>
        </div>
      )}
      {selectedFiles?.length > 0 && !downloadVisible && !uploading &&(
        <div
          className="flex flex-col items-center w-full px-4 pt-44 pb-52 "
          style={{ backgroundColor: "#EEF7FF"}}
        >
          <div className=" text-tacitbase-tertiary1 text-opacity-50 flex flex-col items-center justify-center pb-4">
            {selectedFiles.length === 1 ? (
              <img src={pdf} alt="Your description" className="p-4" />
            ) : (
              <img src={threefile} alt="Your description" className="p-4" />
            )}
            {/* {selectedFiles.length === 1
              ? ` ${folderFileCount} files selected`
              : `${selectedFiles.length} files selected`} */}
              {selectedFiles.length === 1
  ? (selectedFiles[0]?.mimeType && selectedFiles[0]?.mimeType == 'application/vnd.google-apps.folder'
      ? ` ${folderFileCount} files selected`
      : `${capitalizeFirstLetter(selectedFiles[0].name)} file selected`)
  : `${selectedFiles.length} files selected`}
          </div>
          <div className="flex flex-row space-x-4">
            <Button
              type="default"
              onClick={handleDeleteFiles}
              style={{ paddingTop: "4px", paddingBottom: "4px" }}
              icon={<DeleteOutlined />}
            >
              Cancel
            </Button>
            {!uploadSelectionState ? (
              <Button
                type="primary"
                icon={<RocketOutlined />}
                onClick={handleConvertFolder}
                style={{ paddingTop: "4px", paddingBottom: "4px" }}
              >
                Convert{" "}
              </Button>
            ) : (
              <Button
                type="primary"
                icon={<RocketOutlined />}
                onClick={handleConvertFile}
                style={{ paddingTop: "4px", paddingBottom: "4px" }}
              >
                convert{" "}
              </Button>
            )}

            <BillingPlanModal
              isModalOpen={isModalOpen}
              handleCancel={handleCancelilling}
            />
          </div>
        </div>
      )}
      {downloadVisible && !uploading && selectedFiles.length > 0 && (
        <div
          className="flex flex-col items-center w-full px-4 pt-36 pb-36 "
          style={{ backgroundColor: "#EEF7FF"}}
        >
          <div className="text-tacitbase-tertiary1 text-opacity-50 flex flex-col items-center justify-center pb-4">
            <img src={singlexls} alt="Your description" className="p-4" />
          </div>
          <div className="flex flex-row space-x-4">
            <Button
              type="default"
              onClick={handleDeleteFiles}
              style={{ paddingTop: "4px", paddingBottom: "4px" }}
              icon={<ReloadOutlined />}
            >
              Start again
            </Button>
            {/* <Button
              type="primary"
              icon={<DownloadOutlined />}
              //    onClick={}
              style={{ paddingTop: "4px", paddingBottom: "4px" }}
            >
              Download
            </Button> */}
          </div>
          <div className="flex flex-col py-4 space-x-4">
            <div className="text-sm">We are processing your resumes. </div>
            <div> It may take some time, You can download your file in history tab.</div>
            {/* <Button
              type="primary"
              //    onClick={}
              style={{ paddingTop: "4px", paddingBottom: "4px" }}
              icon={<FileOutlined />}
            >
              Add more files{" "}
            </Button> */}
            <div className="flex items-center justify-center mt-4 ">
            <Button type="primary" href="/history" style={{width:'200px'}}>
              History tab
              </Button>
              </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default ConversionWizardMiddlepage;
