import ContentHeader from "../common/ContentHeader";
import HistoryMiddleContent from "../components/HistoryMiddleContent";

function HistoryPage(){
    return(
        <div className="flex flex-col overflow-hidden" >
        <ContentHeader>
            <div className="py-3 flex flex-col  " style={{height:'74px'}}>
                <div className="flex text-base font-semibold items center"> History</div>
            </div>
        </ContentHeader>
        <div className="py-4 px-1 h-full w-full overflow-hidden">
     <HistoryMiddleContent/>

    </div>
    </div>
    )
}
export default HistoryPage;