import React, { useState } from "react";
import { Layout, Button, Row, Col, Drawer, Menu } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import ResumeParserLogo from "../images/RP_180X180.png";
import GoogleIcon from "../Icon/GoogleIcon";

const { Header } = Layout;

const headerStyle: React.CSSProperties = {
  textAlign: "center",
  color: "black",
  height: 64,
  paddingInline: 16,
  lineHeight: "64px",
  width: "100vw",
  backgroundColor: "white",
  borderBottom: "1px solid #EBEBEB",
  alignItems:'center',
  justifyContent:'center'
};

const logoStyle: React.CSSProperties = {
  height: "50px",
marginLeft:'4px',
};

const PublicHeader = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  return (
    <Header style={headerStyle}>
      <Row justify="space-between" align="middle" gutter={[16, 16]} className="h-full">
        <Col xs={20} sm={20} md={12} lg={8}>
          <div className="flex items-center font-semibold text-2xl">
            <img src={ResumeParserLogo} alt="avatar" style={logoStyle} /> Resume Parser
          </div>
        </Col>
        <Col xs={4} sm={4} md={0} lg={0}>
          <Button
            type="text"
            icon={<MenuOutlined />}
            onClick={showDrawer}
          />
        </Col>
        <Col xs={0} sm={0} md={12} lg={16}>
          <div className="flex items-center justify-end space-x-4 ">
            <div className="text-base font-medium">
              <a href="https://www.resumeparser.in/">Home</a>
            </div>
            <div className="text-base font-medium">
              <a href="#pricing-section">Pricing</a>
            </div>
            <div className="text-base font-medium">
              <a href="https://blog.resumeparser.in/">Blogs</a>
            </div>
            <Button
              className="flex items-center justify-center text-base font-medium"
              type="primary"
              href="https://calendar.app.google/9GxWM6XdbvdECJMw9"
            >
              Schedule Demo
            </Button>
            <Button
              icon={<GoogleIcon />}
              href="/sign-up"
              className="flex items-center justify-center text-base font-medium"
            >
              Sign In with Google
            </Button>
          </div>
        </Col>
      </Row>
      <Drawer
        title="Menu"
        placement="right"
        onClose={closeDrawer}
        visible={drawerVisible}
      >
        <Menu mode="vertical">
          <Menu.Item key="home">
            <a href="https://www.resumeparser.in/">Home</a>
          </Menu.Item>
          <Menu.Item key="pricing">
            <a href="#pricing-section"  onClick={closeDrawer} >Pricing</a>
          </Menu.Item>
          <Menu.Item key="blogs">
            <a href="https://blog.resumeparser.in/">Blogs</a>
          </Menu.Item>
          <Menu.Item key="schedule-demo">
            <Button type="primary" href="https://calendar.app.google/9GxWM6XdbvdECJMw9">
              Schedule Demo
            </Button>
          </Menu.Item>
          <Menu.Item key="sign-in">
            <Button icon={<GoogleIcon />} href="/sign-up">
              Sign In with Google
            </Button>
          </Menu.Item>
        </Menu>
      </Drawer>
    </Header>
  );
};

export default PublicHeader;
